import axios from "axios";
// import md5 from 'md5'

/**
 * @param {Object} axiosConfig 用于请求的对象
 * @returns axios实例
 */
function axiosIns(axiosConfig, flag = false) {
  const server = axios.create({
    baseURL: "https://api.panda-gzsap.com",
    timeout: 60000,
  });

  // 请求头拦截设置
  server.interceptors.request.use(
    (config) => {
      // 获取项目中保存的 token
      const token =
        localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
      if (token) {
        config.headers["Authorization"] = token;
      }
      const ignore = config.ignore || "";

      const timestamp = new Date().getTime();
      // md5加密所需的盐
      // const salt = 'kanghehealth8888'
      let params = config.params || "";
      let postBody = config.data || "";
      const { url } = config;
      const urlQuery = url.split("?")[1] || "";
      // let key = ''

      // get请求
      if (params) {
        // http协议中get请求会吧undefined和null自动清除掉，保留参数就是转为空字符串
        Object.keys(params).map((pKey) => {
          if (params[pKey] == null || params[pKey] == undefined) {
            params[pKey] = "";
          }
        });
        params = typeof params === "string" ? params : JSON.stringify(params);
        params = params.replace(/[^a-zA-Z0-9{}]/g, "");
        params = params.replace(/\{|\}/g, "");
      } else {
        // url 拼接
        params = urlQuery.replace(/[^a-zA-Z0-9{}]/g, "");
      }
      // post请求
      if (postBody) {
        postBody =
          typeof postBody === "string" ? postBody : JSON.stringify(postBody);
        postBody = postBody.replace(/[^a-zA-Z0-9{}]/g, "");
      }

      // 对参数进行 md5 加密
      // key = params + postBody
      // const sign = md5(key + timestamp + salt)
      config.headers.ignore = ignore;
      // config.headers.sign = sign
      config.headers.timestamp = timestamp;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // 响应
  server.interceptors.response.use(
    (response) => {
      const { data, headers } = response;
      const resToken = headers["x-authorization"];

      // 更新后端返回的 token
      if (resToken) {
        sessionStorage.setItem("jwtToken", resToken);
      }

      // if (data.code != 200) {
      //     Message({
      //         message: data.message || '未知报错信息',
      //         type: 'warning',
      //         duration: 2 * 1000
      //     })
      //     return Promise.reject(data.message)
      // }
      // 目前项目没有一个确定的状态码判断是成功还是失败，只能先针对几个特殊状态码处理
      //  对特殊状态码做处理
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return server(axiosConfig, flag);
}

export default axiosIns;
